
import { defineComponent, onMounted, ref, computed } from "vue";
import { Form, ErrorMessage } from "vee-validate";

import { DatePicker } from "@progress/kendo-vue-dateinputs";
import { useRouter } from "vue-router";
import { CancellationBlackoutDateModel } from "@/models";
import { restClient } from "@/store/actions";
import { ValFormInput, hasErrors } from "@elite/validation";
import { routePaths } from "@/router";
import ContentCard from "@/components/ContentCard.vue";
import { AppGetterTypes, useStore } from "@/store";
export default defineComponent({
    components: {
        "v-form": Form,
        "val-form-input": ValFormInput,
        "error-message": ErrorMessage,
        "date-picker": DatePicker,
        "content-card": ContentCard
    },
    props: {
        cancellationBlackoutDateId: { type: Number, required: true }
    },
    setup(props) {
        const { getters } = useStore();

        const rules = computed(
            () =>
                getters[AppGetterTypes.validationRules]
                    ?.cancellationBlackoutDateModel
        );

        const router = useRouter();
        const model = ref<CancellationBlackoutDateModel>({
            id: props.cancellationBlackoutDateId,
            blackoutDate: undefined
        });

        const isNew = props.cancellationBlackoutDateId === 0;
        const blackoutDate = computed({
            get: () => {
                if (model.value.blackoutDate) {
                    return new Date(model.value.blackoutDate);
                }
                return undefined;
            },
            set: (val) => {
                if (val) {
                    model.value.blackoutDate = val.toJSON();
                }
            }
        });

        onMounted(async () => {
            if (!isNew) {
                const response =
                    await restClient.getJson<CancellationBlackoutDateModel>(
                        `/api/admin/CancellationBlackoutDate/${props.cancellationBlackoutDateId}`
                    );

                if (response.data) {
                    model.value = response.data;
                    if (response.data.blackoutDate) {
                        model.value.blackoutDate = response.data.blackoutDate;
                    }
                }
            }
        });

        // Submit
        const onSubmit = async (): Promise<void> => {
            if (!model.value.blackoutDate) {
                return;
            }
            //  Save cancellation blackout date data
            const response = isNew
                ? await restClient.postJson<CancellationBlackoutDateModel>(
                      "/api/admin/CancellationBlackoutDate",
                      model.value
                  )
                : await restClient.putJson<CancellationBlackoutDateModel>(
                      "/api/admin/CancellationBlackoutDate",
                      model.value
                  );

            if (hasErrors(response)) {
                return;
            }

            router.push(routePaths.cancellationBlackoutDatePath);
        };

        return {
            onSubmit,
            blackoutDate,
            model,
            routePaths,
            isNew,
            rules
        };
    }
});
